// Logo with link
import { Link } from '@reach/router';
import React from 'react';
import styled from 'styled-components';

const LogoImg = styled.img`
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : '75px')};
`;

interface Props {
  title: string;
  width?: string;
  height?: string;
}

const Logo: React.FC<Props> = function (props) {
  const { title, width, height } = props;

  return (
    <Link to="/" className="short-logo">
      <LogoImg
        src="/images/design/ShortLogo.png"
        alt={title}
        width={width}
        height={height}
      />
    </Link>
  );
};

export default Logo;
