import { Link } from 'gatsby';
import React, { useContext } from 'react';
import MDX from '../cms/MDX';
import styled from 'styled-components';

import Logo from './Logo';
import { device } from '../constants';
import SiteConfigContext from '../context/SiteConfigContext';

const FirstSection = styled.section`
  padding-bottom: calc(${props => props.theme.paddings.pd * 1.5}px);
  margin-bottom: calc(${props => props.theme.paddings.pd * 1.5}px);
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);

  justify-content: center;
  flex-flow: row wrap;

  @media screen and ${device.tablet} {
    justify-content: space-between;
  }
`;

const SecondSection = styled.section`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  & > p,
  & > section {
    flex-basis: 33%;
  }

  @media screen and ${device.laptop} {
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: row wrap;
  }
`;

const AddressSection = styled.p`
  text-align: center;
  order: 1;
  margin-bottom: ${props => props.theme.paddings.pd}px;

  @media screen and ${device.laptop} {
    text-align: left;
  }
`;
const ContactSection = styled.p`
  text-align: center;
  order: 2;
  margin-bottom: calc(${props => props.theme.paddings.pd * 1.5}px);

  @media screen and ${device.laptop} {
    text-align: right;
  }
`;
const SocialMediaSection = styled.section`
  order: 3;

  @media screen and ${device.laptop} {
    order: 2;
  }
`;

const NavList = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
  flex-direction: column;

  @media screen and ${device.tablet} {
    flex-direction: row;
  }
`;
const NavListItem = styled.li`
  padding: 0.5rem 1.5rem;
  font-weight: bold !important;
`;

const TextAlignCenter = styled.div`
  text-align: center;
  margin-bottom: ${props => props.theme.paddings.pd}px;
`;

const SMLink = styled.a`
  display: inline-block;
  margin-left: 20px;

  &:first-child {
    margin-left: 0;
  }
`;

const SMLinkImg = styled.img`
  width: 44px;
  height: 44px;
`;

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.paddings.pd}px;
  font-family: Montserrat, sans-serif;

  &.login ${FirstSection}, &.login ${SecondSection} {
    justify-content: center;
  }

  &.login .short-logo,
  &.login ${AddressSection}, &.login ${ContactSection} {
    display: none;
  }
`;

interface Props {
  title: string;
  login?: boolean;
}

const Footer: React.FC<Props> = function (props) {
  const siteCfg = useContext(SiteConfigContext);

  if (siteCfg == null) {
    return null;
  }

  const { footer_configuration } = siteCfg;
  const { links, address, contact, social_links } = footer_configuration;

  const { title, login } = props;
  const footerClassName = login ? 'login' : 'main';

  return (
    <FooterWrapper className={footerClassName}>
      <FirstSection className="flex-jsb-aic">
        <Logo title={title} />
        <NavList>
          {links.map((item, index) => {
            return (
              <NavListItem key={index}>
                <Link to={item.link}>{item.label}</Link>
              </NavListItem>
            );
          })}
        </NavList>
      </FirstSection>

      <SecondSection>
        <MDX components={{ p: AddressSection }}>{address}</MDX>
        <SocialMediaSection>
          <TextAlignCenter>
            {social_links.map((link: any, index: any) => {
              return (
                <SMLink href={link.link} key={index}>
                  <SMLinkImg
                    src={link.icon}
                    title={`${title} ${link.name}`}
                    alt={`${title} ${link.name}`}
                  />
                </SMLink>
              );
            })}
          </TextAlignCenter>
          <TextAlignCenter>
            &copy; Copyright {new Date().getFullYear()}
            <br />
            {title}
          </TextAlignCenter>
        </SocialMediaSection>
        <MDX components={{ p: ContactSection }}>{contact}</MDX>
      </SecondSection>
    </FooterWrapper>
  );
};

export default Footer;
