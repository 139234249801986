import { Link } from '@reach/router';
import React, { useState } from 'react';
import styled from 'styled-components';
import AdminRoute from './AdminRoute';

import { device } from '../constants';
import LinkButton from './form-elements/LinkButton';
import useFirebaseAuth from '../hooks/useFirebaseAuth';
import useCurrentUserRoles from '../hooks/useCurrentUserRoles';
import SearchIconWithInput from './SearchIconWithInput';

interface LevelProp {
  isOpen: boolean;
  level?: number;
}
const Triangle = styled.a<LevelProp>`
  margin-left: ${props => props.theme.paddings.pd}px;
  padding: 0 !important;

  &::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url('/images/design/submenu-triangle.png');
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: ${props => props.theme.paddings.halfpd / 4}px;
    transform: rotate(0deg);
    transition: all 0.5s;
  }

  &.open::before {
    margin-top: ${props =>
      props.isOpen
        ? props.theme.paddings.halfpd / 2
        : props.theme.paddings.halfpd / 4}px;
    transform: rotate(${props => (props.isOpen ? '90deg' : '0deg')});
    transition: all 0.5s;
  }

  @media screen and ${device.laptop} {
    margin: 0;

    &::before {
      transform: rotate(0deg);
      display: ${props => (props.level == 1 ? 'none' : 'inline-block')};
      margin-top: ${props => props.theme.paddings.halfpd / 4}px;
      margin-left: ${props =>
        props.level == 1 ? '0' : props.theme.paddings.halfpd / 2}px;
    }
  }
`;

interface NavWrapperProps {
  hideMenu?: boolean;
}
const NavWrapper = styled.nav<NavWrapperProps>`
  display: flex;
  flex-flow: column-reverse;
  justify-content: flex-start;
  font-family: Montserrat, sans-serif;

  margin-left: 0;
  flex: 1 0 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height cubic-bezier(0.4, 0, 0.2, 1) 0.25s;

  @media screen and ${device.laptop} {
    visibility: ${props => (props.hideMenu ? 'hidden' : 'initial')};
    flex: 1;
    max-height: initial;
    overflow: initial;
    justify-content: flex-end;
    align-items: center;
    flex-flow: row;
  }

  @media screen and (min-width: 1200px) {
    margin-left: ${props => props.theme.paddings.halfpd}px;
  }
`;

const NavSubList = styled.ul<LevelProp>`
  list-style: none;
  display: ${props => (props.isOpen ? 'block' : 'none')};
  min-width: 210px;
  flex: 1 0 100%;
  text-align: left;

  @media screen and ${device.laptop} {
    text-align: center;
    left: ${props => (props.level == 1 ? '-35%' : '100%')};
    top: ${props => (props.level == 1 ? '100%' : '0')};
    width: -moz-max-content;
    width: max-content;
    max-width: 200px;

    padding: 0;
    position: absolute;
    z-index: 2;
    margin-top: initial;

    display: none;

    & > li {
      background-color: ${props =>
        props.level == 1 ? '#FFF' : props.theme.colors.purple};
      border: 1px solid
        ${props => (props.level == 1 ? props.theme.colors.purple : '#FFF')};
      border-bottom: none;
      transition: background-color 0.5s;

      &:hover {
        background-color: ${props =>
          props.level == 1 ? '#FFF' : props.theme.colors.orangeGray};
        transition: background-color 0.5s;
      }

      & > a {
        color: ${props =>
          props.level == 1 ? props.theme.colors.purple : '#FFf'};
      }
    }
    & > li:last-child {
      border-bottom: 1px solid
        ${props => (props.level == 1 ? props.theme.colors.purple : '#FFF')};
    }
  }
`;

const NavListItem = styled.li`
  position: relative;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row wrap;

  & a {
    padding: ${props => props.theme.paddings.halfpd * 0.25}px
      ${props => props.theme.paddings.halfpd}px;
    display: block;

    @media screen and (min-width: 768px) {
      font-size: 0.8em;
    }

    @media screen and (min-width: 1200px) {
      font-size: 0.9em;
    }
  }

  @media screen and ${device.laptop} {
    justify-content: center;
    padding: 0 0.5rem;
    flex-wrap: nowrap;

    &:hover > ${NavSubList} {
      display: block;
    }
  }
`;

const NavList = styled.ul`
  margin-top: ${props => props.theme.paddings.halfpd}px;
  margin-bottom: 0;
  list-style: none;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0;

  & > ${NavListItem}:after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 5px;
    left: 50%;
    right: 50%;
    background-color: transparent;
    transition: all 0.5s;
  }
  & > ${NavListItem}:hover:after {
    background-color: ${props => props.theme.colors.purple};
    left: 30%;
    right: 30%;
    transition: all 0.5s;
  }
  & > ${NavListItem} a {
    padding: ${props => props.theme.paddings.halfpd}px 0;
  }

  & ${NavListItem} ${NavListItem} {
    padding: ${props => props.theme.paddings.halfpd * 0.25}px
      ${props => props.theme.paddings.halfpd}px;
  }

  @media screen and ${device.laptop} {
    align-items: center;
    flex-direction: row;
    margin-top: 0;

    & > ${NavListItem} a {
      padding: ${props => props.theme.paddings.halfpd}px 0.5rem;
    }

    & ${NavListItem} ${NavListItem} {
      padding: 0;
    }
  }
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  align-items: center;
  grid-gap: 16px;
  flex: 1 1;

  @media screen and (min-width: 768px) {
    button {
      font-size: 0.7em;
    }
  }

  @media screen and (min-width: 1200px) {
    button {
      font-size: 0.7em;
    }
  }
`;

interface NavChildProps {
  item: App.MenuItem;
  index: number;
  level: number;
}

const NavChild: React.FC<NavChildProps> = function (props) {
  const { item, index, level } = props;

  const [isOpen, setOpen] = useState(false);
  const submenuId = `level-${level}-item-${index}-submenu`;

  if (!item.children || item.children.length == 0) {
    return (
      <NavListItem key={index}>
        {item.link && <Link to={item.link}>{item.name}</Link>}
      </NavListItem>
    );
  }

  const handleOpen = (e: any) => {
    e.preventDefault();
    setOpen(!isOpen);
  };

  return (
    <NavListItem key={index}>
      <Link to={item.link}>{item.name}</Link>
      <Triangle
        href={`#${submenuId}`}
        isOpen={isOpen}
        onClick={handleOpen}
        level={level}
      />
      <NavSubList
        level={level}
        isOpen={isOpen}
        aria-expanded={isOpen}
        id={submenuId}
      >
        {item.children?.map((i: any, key: number) => (
          <NavChild key={key} item={i} index={key} level={level + 1} />
        ))}
      </NavSubList>
    </NavListItem>
  );
};

interface Props {
  data: App.MenuItem[];
  hideMenu?: boolean;
}

const Nav: React.FC<Props> = function (props) {
  const { currentUser } = useFirebaseAuth();
  const { isAdmin } = useCurrentUserRoles();
  const { hideMenu, data } = props;

  return (
    <NavWrapper hideMenu={hideMenu}>
      <NavList>
        {data.map((item: App.MenuItem, key: number) => (
          <NavChild key={key} item={item} index={key} level={1} />
        ))}
      </NavList>

      <SearchIconWithInput />

      <ButtonContainer>
        {currentUser === null && (
          <>
            {/* disable login, coming soon*/}
            {/*
            <LinkButton
              link="/login"
              buttonText="Login"
              buttonType="outline"
              background="#FFF"
            />
              */}
            <LinkButton
              link="/"
              buttonText="Coming Soon"
              buttonType="outline"
              background="#FFF"
            />
            <LinkButton
              link="/apply"
              buttonText="Join as Member"
              buttonType="primary"
            />
          </>
        )}
        {isAdmin && (
          <LinkButton link="/manage" buttonText="Admin" buttonType="primary" />
        )}
        {currentUser !== null && (
          <>
            <LinkButton
              link="/dashboard"
              buttonText="Dashboard"
              buttonType="primary"
            />
            <LinkButton
              link="/logout"
              buttonText="Log out"
              buttonType="outline"
              background="#FFF"
            />
          </>
        )}
      </ButtonContainer>
    </NavWrapper>
  );
};

export default Nav;
