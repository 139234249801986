import { useCallback, useEffect, useState } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { User } from 'firebase';

interface FirebaseAuthHook {
  loading: boolean;
  currentUser: User | null;
}

export default function useFirebaseAuth(): FirebaseAuthHook {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  const handleAuthStateChanged = useCallback(user => {
    setUser(user);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!firebase.auth) {
      return;
    }
    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(handleAuthStateChanged);

    return () => unsubscribe();
  }, [handleAuthStateChanged]);

  return {
    loading,
    currentUser: user,
  };
}
