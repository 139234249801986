import React, { FormEvent, useState } from 'react';
import { useNavigate } from '@reach/router';
import styled from 'styled-components';
import Input from './form-elements/Input';
import { device } from '../constants';

interface SearchFormProps {
  expanded: boolean;
}
const SearchForm = styled.form<SearchFormProps>`
  display: flex;
  flex-flow: row-reverse;
  align-items: center;
  transition: flex 0.75s ease-out;
  padding: 0.375rem 0.35rem 0.35rem;
  border: 2px solid transparent;

  margin: 2rem 0;
  flex: 1 0;
  border: 2px solid ${props => props.theme.colors.purple};
  max-width: 100%;

  & input {
    width: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    color: ${props => props.theme.colors.purple};
    flex: 1 0;
  }

  & input,
  & button {
    outline: none;
  }

  @media screen and ${device.laptop} {
    margin-right: 1rem;
    margin-left: auto;
    margin-top: 0;
    margin-bottom: 0;
    border: 2px solid transparent;
    flex: 0 1;
    max-width: 200px;

    ${props => {
      return props.expanded
        ? `
          border: 2px solid ${props.theme.colors.purple};
          flex: 1 0;
        `
        : '';
    }}
  }

  @media screen and (min-width: 768px) {
    display: none;
  }

  @media screen and (min-width: 1200px) {
    display: flex;
  }
`;

const SearchIcon = styled.button`
  cursor: pointer;
  background-size: 100%;
  background-image: url('images/design/search-icon.png');
  background-color: transparent;

  border: none;
  padding: 0;
  margin-right: 0.5rem;
  display: block;
  width: 26px;
  height: 26px;
`;

// Expands when search icon is clicked,
// submits when input is filled and icon is clicked
const SearchIconWithInput: React.FC = function () {
  const [isOpen, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const handleClick = () => {
    if (isOpen && searchQuery.length > 0) {
      // Submit the form - navigate
      navigate(`/search?q=${searchQuery}`, { state: { query: searchQuery } });
    }

    setOpen(!isOpen);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    navigate(`/search?q=${searchQuery}`, { state: { query: searchQuery } });
  };

  return (
    <SearchForm
      expanded={isOpen}
      role="search"
      method="get"
      action="/search"
      onSubmit={handleSubmit}
    >
      <Input
        type="text"
        placeholder="Search"
        value={searchQuery}
        onChange={(e: any) => setSearchQuery(e.target.value)}
      />
      <SearchIcon type="button" data-toggle={isOpen} onClick={handleClick} />
    </SearchForm>
  );
};

export default SearchIconWithInput;
