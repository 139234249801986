import { useEffect, useState } from 'react';
import useFirebaseAuth from '../hooks/useFirebaseAuth';

export default function useCurrentUserRoles() {
  const { loading, currentUser } = useFirebaseAuth();
  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => {
    async function checkAdmin() {
      if (currentUser === null) {
        return;
      }
      const { claims } = await currentUser.getIdTokenResult(true);
      if (Array.isArray(claims.roles)) {
        setRoles(claims.roles);
      }
    }
    checkAdmin();
  }, [currentUser]);

  return {
    roles,
    isAdmin: roles.indexOf('admin') !== -1,
  };
}
